import { useState, useEffect } from 'react'

export default function useIsMobile() {
  const [isPhone, setPhone] = useState(false)

  useEffect(() => {
    const mediaQueryList = window.matchMedia('(max-width: 812px)')
    setPhone(mediaQueryList.matches)

    const listeren = (mediaQueryList) => {
      setPhone(mediaQueryList.matches)
    }

    mediaQueryList.addEventListener('change', listeren)

    return () => mediaQueryList.removeEventListener('change', listeren)
  }, [])

  return isPhone
}
