import React, { useState, useEffect, useRef } from 'react'
import { FormattedMessage, useIntl, Link } from 'gatsby-plugin-react-intl'
import { StaticImage } from 'gatsby-plugin-image'
import BScroll from '@better-scroll/core'

import { Footer, Layout } from '../components/layout'
import Seo from '../components/seo'

import {
  IndexSection,
  SectionBackgroundVideo,
  TitleContainer,
  ViewportContainer,
  PageContainer,
  PageNavContainer,
  PageNavIndex,
  PageScrollTime,
  MouseIconContainer,
  TitleSection,
  PageNavArrow,
  PageNavArrowContainer,
  PageSidebarContainer,
  SectionIntroContainer,
  SectionIntroContent,
  SectionCaseImageContainer,
  SectionRayvisionLine,
  ProductionContainer,
  ProductionCard,
  CompanyImage,
  CompanyContainer,
  CompanyFieldContainer,
  TeamContainer,
  TeamCard,
  FadeInItemContainer,
  ContactShenzhenContainer,
  ContactContainer,
  TeamJoinLink,
  SectionAboutIntroContainer,
  ProductionOutterContainer,
} from '../style/pages/index.atom'

import ShenzhenVideo from '../assets/video/shenzhen.mp4'
import { MouseIcon, GoIcon } from '../components/icons'
import { LangType } from '../constant'
import useIsMobile from '../utils/isMobile'
import {
  EnCompanyFieldList,
  CnCompanyFieldList,
  ContactInfoList,
  ContactShenzhenList,
  EnProductionList,
  CnProductionList,
  TeamInfoList,
  EnCompanyImageList,
  CnCompanyImageList,
} from '../constant/index-page-data'

let wheelTimer = NaN
let bscroll = null
let lastScrollY = 0

const IndexPage = () => {
  const [index, setCurrentIndex] = useState(0)
  const [beenEnterList, setBeenEnterList] = useState([])
  const PageRef = useRef(null)
  const ViewportrRef = useRef(null)
  const intl = useIntl()
  const ProductionList = {
    [LangType.EN]: EnProductionList,
    [LangType.CN]: CnProductionList,
  }[intl.locale]
  const CompanyFieldList = {
    [LangType.EN]: EnCompanyFieldList,
    [LangType.CN]: CnCompanyFieldList,
  }[intl.locale]
  const CompanyImageList = {
    [LangType.EN]: EnCompanyImageList,
    [LangType.CN]: CnCompanyImageList,
  }[intl.locale]
  const IsCN = intl.locale === LangType.CN
  const SectionLength = IsCN ? 6 : 5
  const NavList = Array.from({ length: SectionLength }, (_, index) => index)

  const setPageTransform = (delta) => {
    if (PageRef.current) {
      PageRef.current.style.transform = `translateY(-${delta})`
    }
  }
  const setPageTransition = (option = {}) => {
    if (PageRef.current) {
      Reflect.ownKeys(option).forEach((key) => {
        PageRef.current.style[key] = option[key]
      })
    }
  }
  const setPageChildrenPoninterEventNull = () => {
    if (PageRef.current) {
      ;[...PageRef.current.children].forEach((e) => {
        e.style.pointerEvents = null
      })
    }
  }
  const handleWheelUpdate = (event) => {
    const delta = Math.sign(event.deltaY)
    const currentIndex = index + delta
    const isMaxIndex = currentIndex + 1 > SectionLength
    const isMinIndex = currentIndex < 0
    const minDelta = Math.abs(event.deltaY) > 20
    if (minDelta && !isMaxIndex && !isMinIndex && isNaN(wheelTimer)) {
      setCurrentIndex(currentIndex)
      setPageTransform(`${100 * currentIndex}vh`)
      pushEnterIndex(currentIndex)
      wheelTimer = setTimeout(() => {
        wheelTimer = NaN
      }, PageScrollTime)
    }
  }

  const handleScrollUpdate = (event) => {
    setPageTransition({
      'transition-duration': null,
      'transition-property': null,
      'transition-timing-function': null,
    })
    let delta
    if (event.y < -0.82 * window.innerHeight * SectionLength) {
      delta = 1
    } else if (event.y < -600) {
      delta = -Math.sign(event.y - lastScrollY)
    } else {
      delta = -Math.sign(event.y)
    }
    const currentIndex = index + delta
    const isMaxIndex = currentIndex + 1 > SectionLength
    const isMinIndex = currentIndex < 0
    const minDelta = Math.abs(event.y) > 20
    lastScrollY = event.y
    if (minDelta && !isMaxIndex && !isMinIndex) {
      setCurrentIndex(currentIndex)
      setPageTransform(`${100 * currentIndex}vh`)
      pushEnterIndex(currentIndex)
    } else {
      handleUpdateIndex(index)
    }
    setPageChildrenPoninterEventNull()
  }

  useEffect(() => {
    bscroll = new BScroll(ViewportrRef.current, {
      probeType: 2,
      click: true,
      eventPassthrough: 'horizontal',
    })
    bscroll.on('scroll', handleScrollUpdate)
    window.addEventListener('wheel', handleWheelUpdate)
    // window.addEventListener('touchstart', handleTouchStart)
    // window.addEventListener('touchmove', handleTouchlUpdate)
    // window.addEventListener('touchend', handleTouchEnd)
    return () => {
      window.removeEventListener('wheel', handleWheelUpdate)
      // window.removeEventListener('touchstart', handleTouchStart)
      // window.removeEventListener('touchmove', handleTouchlUpdate)
      // window.removeEventListener('touchend', handleTouchEnd)
      bscroll.off('scroll', handleScrollUpdate)
      bscroll.destroy()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index])

  const pushEnterIndex = (index) => {
    if (!beenEnterList.includes(index)) {
      setBeenEnterList([...beenEnterList, index])
      return true
    }
    return false
  }

  const handleUpdateIndex = (currentIndex) => {
    const isMaxIndex = currentIndex + 1 > SectionLength
    const isMinIndex = currentIndex < 0
    if (!isMaxIndex && !isMinIndex) {
      setCurrentIndex(currentIndex)
      setPageTransform(`${100 * currentIndex}vh`)
      pushEnterIndex(currentIndex)
    } else {
      setPageTransform(`${100 * index}vh`)
    }
  }

  const isMobile = useIsMobile()

  const navigateToLink = (link) => {
    if (isMobile) {
      const windowOpen = window.open('', '_blank')
      windowOpen.location = link
    }
  }

  return (
    <Layout hiddenFooter={true}>
      <Seo
        title={intl.formatMessage({ id: 'index_seo.title' })}
        description={intl.formatMessage({ id: 'index_seo.description' })}
        keywords={intl.formatMessage({ id: 'index_seo.keywords' })}
      />
      <ViewportContainer ref={ViewportrRef}>
        <PageContainer ref={PageRef}>
          <TitleSection>
            <TitleContainer>
              {IsCN && (
                <h3>
                  RAYVISION VISUAL CLOUD
                  <br />
                  COMPUTING PLATFORM
                </h3>
              )}
              <h1>
                <FormattedMessage id='index_title.rayvision' /> <br />
                <FormattedMessage id='index_title.visual' />
                <strong>
                  <FormattedMessage id='index_title.cloud' />
                  {!IsCN && <br />}
                  <FormattedMessage id='index_title.computing' />
                </strong>
                <br /> <FormattedMessage id='index_title.platform' />
              </h1>
              <p>
                <FormattedMessage id='index_subtitle' />
              </p>
            </TitleContainer>
            <MouseIconContainer>
              <MouseIcon />
            </MouseIconContainer>
            <SectionBackgroundVideo>
              <video autoPlay={true} muted loop playsInline={true} webkit-playsinline='true'>
                <source type='video/mp4' src={ShenzhenVideo} rel='nofollow' />
              </video>
            </SectionBackgroundVideo>
          </TitleSection>
          <IndexSection>
            <SectionAboutIntroContainer>
              <SectionIntroContent isEnter={beenEnterList.includes(1)}>
                <h2>ABOUT RAYVISION</h2>
                {IsCN && <h1>关于瑞云</h1>}
                <p>
                  <FormattedMessage id='index_about_intro' />
                </p>
                <SectionRayvisionLine />
                <p className='data-source'>
                  <FormattedMessage id='index_about_data_source' />
                </p>
              </SectionIntroContent>
              <SectionCaseImageContainer isEnter={beenEnterList.includes(1)}>
                <StaticImage alt='case' src='../images/index/case.png' />
              </SectionCaseImageContainer>
            </SectionAboutIntroContainer>
            <StaticImage
              className='background-image'
              layout='fullWidth'
              alt=''
              src='../images/index/index-bg-1.png'
            />
          </IndexSection>
          <IndexSection>
            <SectionIntroContainer>
              <SectionIntroContent isEnter={beenEnterList.includes(2)}>
                <h2>OUR PRODUCTS</h2>
                {IsCN && <h1>瑞云产品</h1>}
                <p>
                  <FormattedMessage id='index_product_intro' />
                </p>
                <SectionRayvisionLine />
              </SectionIntroContent>
              <ProductionOutterContainer>
                <ProductionContainer>
                  {ProductionList.map((info, index) => (
                    <ProductionCard
                      onClick={() => navigateToLink(info.linkTo)}
                      key={info.title}
                      isEnter={beenEnterList.includes(2)}
                      index={info.index}
                      size={info.size}
                      scale={info.scale}
                    >
                      {info.icon}
                      <h5>
                        <FormattedMessage id={info.title} />
                      </h5>
                      <p>
                        <FormattedMessage id={info.intro} />
                      </p>
                      <a href={info.linkTo} target='_blank' rel='noreferrer'>
                        <FormattedMessage id={info.linkText} />
                      </a>
                    </ProductionCard>
                  ))}
                </ProductionContainer>
              </ProductionOutterContainer>
            </SectionIntroContainer>
            <StaticImage
              className='background-image'
              layout='fullWidth'
              alt=''
              src='../images/index/index-bg-2.png'
            />
          </IndexSection>
          <IndexSection>
            <SectionIntroContainer>
              <SectionIntroContent isEnter={beenEnterList.includes(3)}>
                <h2>OUR CLIENTS</h2>
                {IsCN && <h1>瑞云科技视觉云计算面向的行业用户</h1>}
                <p>
                  <FormattedMessage id='index_company_intro.0' />
                  {IsCN && (
                    <>
                      <br />
                      <FormattedMessage id='index_company_intro.1' />
                    </>
                  )}
                </p>
                <SectionRayvisionLine />
                <CompanyFieldContainer>
                  {CompanyFieldList.map((field, index) => (
                    <FadeInItemContainer
                      key={index}
                      isEnter={beenEnterList.includes(3)}
                      index={index}
                    >
                      {field}
                    </FadeInItemContainer>
                  ))}
                </CompanyFieldContainer>
              </SectionIntroContent>
              <CompanyContainer isEnter={beenEnterList.includes(3)}>
                {CompanyImageList.map((img, index) => (
                  <CompanyImage
                    src={img}
                    key={index}
                    alt='company'
                    isEnter={beenEnterList.includes(3)}
                    index={index}
                  />
                ))}
              </CompanyContainer>
            </SectionIntroContainer>
            <StaticImage
              className='background-image'
              layout='fullWidth'
              alt=''
              src='../images/index/index-bg-3.png'
            />
          </IndexSection>
          <IndexSection>
            <SectionIntroContainer>
              <SectionIntroContent isEnter={beenEnterList.includes(4)}>
                <h2>OUR TEAM</h2>
                {IsCN && <h1>我们拥有经验丰富的服务及系统开发团队</h1>}
                <p>
                  <FormattedMessage id='index_team_intro' />
                </p>
                <SectionRayvisionLine />
                {IsCN && (
                  <Link to='https://rayvision.zhiye.com/' rel='nofollow'>
                    <TeamJoinLink isEnter={beenEnterList.includes(4)} delay={1.7}>
                      <span>加入我们</span>
                      <GoIcon />
                    </TeamJoinLink>
                  </Link>
                )}
              </SectionIntroContent>
              <TeamContainer isEnter={beenEnterList.includes(4)}>
                {TeamInfoList.map((info, index) => (
                  <TeamCard key={info.title} isEnter={beenEnterList.includes(4)} index={index}>
                    {info.icon}
                    <p>
                      <FormattedMessage id={info.title} />
                    </p>
                  </TeamCard>
                ))}
              </TeamContainer>
            </SectionIntroContainer>
            <StaticImage
              className='background-image'
              layout='fullWidth'
              alt=''
              src='../images/index/index-bg-4.png'
            />
          </IndexSection>
          {IsCN && (
            <IndexSection>
              <SectionIntroContainer>
                <SectionIntroContent isEnter={beenEnterList.includes(5)}>
                  <h2>CONTACT US</h2>
                  <h1>联系我们</h1>
                  <ContactShenzhenContainer isEnter={beenEnterList.includes(5)}>
                    <h5>深圳总部</h5>
                    <ul>
                      {ContactShenzhenList.map((info, index) => (
                        <FadeInItemContainer
                          as='li'
                          key={index}
                          isEnter={beenEnterList.includes(5)}
                          index={index}
                          delay={1.15}
                          spacing={0.05}
                        >
                          {info}
                        </FadeInItemContainer>
                      ))}
                    </ul>
                  </ContactShenzhenContainer>
                  <SectionRayvisionLine />
                </SectionIntroContent>
                <ContactContainer isEnter={beenEnterList.includes(5)}>
                  {ContactInfoList.map((info, index) => (
                    <FadeInItemContainer
                      key={index}
                      isEnter={beenEnterList.includes(5)}
                      index={index}
                      hiddenOnMobile={!!info.hiddenOnMobile}
                      containerWidth={info.width}
                      marTop={info.marTop}
                      paddingLeft={15}
                    >
                      <h5>{info.title}</h5>
                      {info.info.map((line, infoIndex) => (
                        <p key={infoIndex}>{line}</p>
                      ))}
                    </FadeInItemContainer>
                  ))}
                </ContactContainer>
              </SectionIntroContainer>
              <StaticImage
                className='background-image'
                layout='fullWidth'
                alt=''
                src='../images/index/index-bg-5.png'
              />
            </IndexSection>
          )}
          <Footer />
        </PageContainer>
        <PageSidebarContainer>
          <PageNavContainer>
            {NavList.map((value) => (
              <PageNavIndex
                key={value}
                onClick={() => {
                  handleUpdateIndex(value)
                }}
                activited={value === index}
              >
                {String(value + 1).padStart(2, 0)}
              </PageNavIndex>
            ))}
          </PageNavContainer>
          <PageNavArrowContainer>
            <PageNavArrow
              onClick={() => {
                handleUpdateIndex(index - 1)
              }}
            />
            <PageNavArrow
              direction='next'
              onClick={() => {
                handleUpdateIndex(index + 1)
              }}
            />
          </PageNavArrowContainer>
        </PageSidebarContainer>
      </ViewportContainer>
    </Layout>
  )
}

export default IndexPage
